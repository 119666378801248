.faq {

    p span, .label span, .card-body span {
        color: $brand-span-light;
        font-weight: bold;
    }

    .icon-question-mark {
        background: url('/images/question_mark.svg') no-repeat left top;
        width: 115px;
        height: 190px;
        position: absolute;
        left: 12%;
    }

    .container {

        h2 {
            font-size: 20px;
            font-weight: 700;
            line-height: 29px;
        }

        h3 {
            font-size: 18px;
            font-weight: 700;
        }
    }

    .faq-downloads {
        background: $brand-sec;
        padding: 40px;

        ul {
            li {
                list-style: none;

                a {
                    color: $brand-third;
                    display: inline-flex;
                    align-items: center;

                    span.icon {
                        margin-right: 20px;

                        &:before {
                            background: url("/images/download_icon.svg") $brand-third no-repeat center;
                            border-radius: 50%;
                            content: " ";
                            display: inline-flex;
                            height: 60px;
                            width: 60px;
    
                        }
                    }
                }
            }
        }
    }

    .accordion {
        padding: 0;
        margin: 0 -40px;

        .container {
            .card-body {
                padding-left: 0;

                .card-body {
                    padding-left: 58px;
                    font-size: 1rem;
                }
            }
        }

        .label {
            small {
                display: block;
            }
        }
    }

    #stories {
        .story {
            background-color: #73b7d1;
            cursor: pointer;
        }
    }

    .no-scrollbar {
        scrollbar-width: none; /* For Firefox */
        -ms-overflow-style: none; /* For IE and Edge */
        overflow: hidden;

        &::-webkit-scrollbar {
            display: none; /* For Chrome, Safari, and Opera */
        }

        .no-scroll-item {
            margin: 0 -15px 0 0;
        }
    }

    .btn {
        &.slide-left, &.slide-right {
            position: absolute; 
            z-index: 1; 
            top: 50%;
            color: #fff;
            background: rgba(0,0,0,0.2);
            border-radius: 50%;
            text-align: unset;
            transform: translateY(-50%);
            
            &:before {
                display: inline-block;
                font-family: "flexslider-icon";
                font-size: 36px;
                padding: 0 10px;
            }
        }

        &.slide-left {
            left: 0px; 

            &:before {
                content: "\f001";
            }
        }

        &.slide-right {
            right: 0px;

            &:before {
                content: "\f002";
            }
        }
    }

    .switch {
        .btn {
            border: 1px solid #376a85;
        }
    }

    #switch {
        display: none;
    }

    #switch:not(:checked) ~ #stories .faq-stories {
        display: block;
    }

    #switch:not(:checked) ~ #stories .switch .videoswitch {
        display: none;
    }

    #switch:checked ~ #stories .faq-text .faq-content {
        display: block !important;
    }

    #switch:checked ~ #stories .faq-stories {
        display: none;
    }

    #switch:checked ~ #stories .switch .textswitch {
        display: none !important;
    }

    #switch:checked ~ #stories .switch .videoswitch {
        display: block !important;
    }
}

.reels {
    .modal {
        overflow: hidden;
        background: rgb(10, 37, 56);
        padding-left: 0 !important;
        align-items: center;

        .controls {
            bottom: 0;
            display: flex;
            justify-content: space-evenly;
            position: absolute;
            width: 100%;
            z-index: 999;

            .close, .play, .mute, .rewind, .forward {
                background: transparent;
                opacity: 1;
            }

            .close {
                float: unset;
            }

            .play, .mute, .rewind, .forward {
                border: none;
            }
        }

        .modal-dialog {
            margin: 0 auto;
            border-radius: 0;
            max-width: calc(100vh * 0.48);
            height: 100%;
            
            button img {
                width: 52px;
            }
        }

        .modal-body {
            padding: 0;
        }

        .modal-content {
            background: transparent;
            border: 0;
        }

        .blur {
            backdrop-filter: blur(8px) saturate(120%);
            background: rgba(0, 0, 0, 0.6);
            height: 100vh;
            position: absolute;
            width: 100vw;
        }
    }

    .carousel {
        .carousel-indicators {
            top: 10px;
            margin-right: 10px;
            margin-left: 10px;

            li {
                width: 50%;
                opacity: 1;
                background-color: rgba(255, 255, 255, 0.5);

                &.watched {
                    background-color: rgba(255, 255, 255, 1) !important;
                }
            }
        }

        

        .carousel-control-prev {
            left: -100px;
        }

        .carousel-control-next {
            right: -100px;
        }

        .carousel-control-next-icon {
            background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' viewBox='0,0,270,389.99999999999943'%3E%3Cg%3E%3Cpath d='M0,0M258.67261443,218.39487975 C258.67261443,218.39487975,94.67261443,381.39487975,94.67261443,381.39487975 C88.67261443,387.39487975,81.3392811,390.39487975,72.67261443,390.39487975 C64.00594776,390.39487975,56.67261443,387.39487975,50.67261443,381.39487975 C50.67261443,381.39487975,30.67261443,362.39487975,30.67261443,362.39487975 C24.67261443,355.72821309,21.67261443,348.06154642,21.67261443,339.39487975 C21.67261443,330.72821309,24.67261443,323.39487975,30.67261443,317.39487975 C30.67261443,317.39487975,152.67261443,195.39487975,152.67261443,195.39487975 C152.67261443,195.39487975,30.67261443,74.39487975,30.67261443,74.39487975 C24.67261443,67.72821309,21.67261443,60.06154642,21.67261443,51.39487975 C21.67261443,42.72821309,24.67261443,35.39487975,30.67261443,29.39487975 C30.67261443,29.39487975,50.67261443,10.39487975,50.67261443,10.39487975 C56.00594776,3.72821309,63.3392811,0.39487975,72.67261443,0.39487975 C82.00594776,0.39487975,89.3392811,3.72821309,94.67261443,10.39487975 C94.67261443,10.39487975,258.67261443,173.39487975,258.67261443,173.39487975 C264.67261443,179.39487975,267.67261443,186.72821309,267.67261443,195.39487975 C267.67261443,204.06154642,264.67261443,211.72821309,258.67261443,218.39487975Z '/%3E%3C/g%3E%3C/svg%3E")#fff;
            background-position: 60% center;
            background-repeat: no-repeat;
            background-size: 50%;
            border-radius: 100%;
            height: 50px;
            width: 50px;
        }

        .carousel-control-prev-icon {
            background: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0,0,270,389.99999999999943'%3E%3Cg%3E%3Cpath d='M0,0M30.67261443,218.39487975 C24.67261443,211.72821309,21.67261443,204.06154642,21.67261443,195.39487975 C21.67261443,186.72821309,24.67261443,179.39487975,30.67261443,173.39487975 C30.67261443,173.39487975,194.67261443,10.39487975,194.67261443,10.39487975 C200.00594776,3.72821309,207.3392811,0.39487975,216.67261443,0.39487975 C226.00594776,0.39487975,233.3392811,3.72821309,238.67261443,10.39487975 C238.67261443,10.39487975,258.67261443,29.39487975,258.67261443,29.39487975 C264.67261443,35.39487975,267.67261443,42.72821309,267.67261443,51.39487975 C267.67261443,60.06154642,264.67261443,67.72821309,258.67261443,74.39487975 C258.67261443,74.39487975,136.67261443,195.39487975,136.67261443,195.39487975 C136.67261443,195.39487975,258.67261443,317.39487975,258.67261443,317.39487975 C264.67261443,323.39487975,267.67261443,330.72821309,267.67261443,339.39487975 C267.67261443,348.06154642,264.67261443,355.72821309,258.67261443,362.39487975 C258.67261443,362.39487975,238.67261443,381.39487975,238.67261443,381.39487975 C232.67261443,387.39487975,225.3392811,390.39487975,216.67261443,390.39487975 C208.00594776,390.39487975,200.67261443,387.39487975,194.67261443,381.39487975 C194.67261443,381.39487975,30.67261443,218.39487975,30.67261443,218.39487975Z '/%3E%3C/g%3E%3C/svg%3E") #fff;
            background-position: 40% center;
            background-repeat: no-repeat;
            background-size: 50%;
            border-radius: 100%;
            height: 50px;
            width: 50px;
        }

        iframe {
            border-radius: 15px;
        }
    }
}

@include media-breakpoint-up(sm) {
    .faq {
        .btn {
            &.slide-left, &.slide-right {
                color: #fff;
                background: rgba(0,0,0,0.2);
            }

            &.slide-left {
                left: -25px; 

                &:before {
                    content: "\f001";
                }
            }

            &.slide-right {
                right: -25px;

                &:before {
                    content: "\f002";
                }
            }
        }
    }
}

@include media-breakpoint-up(md) {
    .faq {
        .container {
            h2 {
                font-size: 40px;
                font-weight: 700;
                line-height: 29px;
            }
    
            h3 {
                font-size: 40px;
                font-weight: 700;
            }
        }

        .accordion {
            padding: 0;
            margin: 0 -40px;
    
            .container {
                .card-body {
                    padding-left: 58px;
                }
            }
        }

        .no-scrollbar {
            overflow: auto;
        }

        .btn {
            &.slide-left, &.slide-right {
                color: $brand-app;
                background: rgba(0,0,0,0);
            }

            &.slide-left {
                left: -40px; 
    
                &:before {
                    content: "\f001";
                }
            }
    
            &.slide-right {
                right: -40px;
    
                &:before {
                    content: "\f002";
                }
            }
        }
    }

    .page_6 .faq .accordion .accordion{
        margin: 0 -40px !important;
    }
}

.page_1 .faq .accordion, .page_6 .faq .accordion {
    margin: 0;
}

.page_6 .faq .accordion .accordion{
    margin: 0;
}



@media (min-width: 1025px) {
    .faq .reels .modal-dialog {
        button img {
            width: 52px;
        }
    }
}