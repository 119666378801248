.block {
    margin: 10px 0 60px 0;

    .row {
        padding: 0;

        h3 {
            font-size: 2rem;
        }

        .bob-style {
            background: $brand-app;
            color: #fff;
            padding: 8px 40px;
        }
    }

    p span {
        color: $brand-span-light;
        font-weight: bold;
    }

    .bg {
        background: $brand-sec;

    }

    &.dark {
        .bg {
            background: $card-right-bg-page1;
            color: #fff;
        }

        .row {
            padding: 50px 40px;
            .col {
                align-self: center;
            }
        }
    }
    
}

@include media-breakpoint-up(md) {
    .block {
        margin: 10px 0 60px 0;

        .row {
            padding: 40px;
        }
    }
}