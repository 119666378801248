.usps {

    h3 {
        font-size: 28px;
        font-weight: 700;
        color: #000;
    }

    .usp-content {
        .usp-cards {
            text-align: center;
            min-height: unset;

            .usp-icon {
                margin: 0 auto;
            }

            .title {
                color: $usp-font-title;
            }

            p {
                color: #000;
                padding-left: 0;
            }

            
        }
    }

    span {
        color: $brand-span-dark;
    }
}

.page_5 .usps {
    .usp-content .usp-cards {
        text-align: left;

        .usp-details-desktop {
            display: block;
        }
        .usp-heading {
            display: none;
        }
    }
}

.usps-arrow {
    margin: 0 auto 60px auto;
    border-left: 70px solid transparent;
    border-right: 70px solid transparent;
    border-top: 60px solid $usp-bg;
    border-bottom: 0 none;
    width: 0;
    height: 0;
}

#bofu-landing {
    h2 {
        font-size: 1.8rem;

        .text-brand {
            color: #362a62;
            font-weight: bold;
        }
    }

    .usps {
        background-color: #fff;

        .title {
            color: #362a62;
            padding: 10px 0px 0px 10px;
        }
    }
}

.usps {
    .usp-cards {
        img {
            max-height: 104px;
        }
    }
}

@include media-breakpoint-up(lg) {
    .usps {
        .usp-cards {
            min-height: 220px;
            margin-bottom: 0px;

            .usp-details-desktop {
                display: block;
            }

            .usp-details-mobile {
                display: none;
            }

            .usp-heading p {
                padding-left: 0;
            }
        }
    }
}
