h2 {
    font-size: 40px;
    font-weight: 700;
    line-height: 1.15;
    color: #3b3b3b;
    margin-bottom: 40px;

    span {
        color: $brand-span-light;
        font-weight: bold;
    }
}

.page_16 {
    h1 {
        font-size: 26px;
    }

    .passage {
        padding-top: 0px;

        p {
            font-size: 14px;
        }
    }
}