
.contact, .page_4 .contact {

    h3 {
        font-size: 40px;
    }

    padding-bottom: 30px;

    .contact-cards {
        padding-bottom: 0;
    }

    .card {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .card-left, .card-right {
        a {
            color: $brand-heading-dark;
            white-space: nowrap;
            font-weight: 700;
        }

        .bob-style {
            color: #fff;
            background-color: $card-button-background;
        }
        
        span {
            color: $brand-app;
        }
    }

    .card-left {
        background-color: $card-left-bg-page1;
        color: $brand-heading-dark;
    }

    .card-top-wrapper {
        display: flex;
        margin-bottom: 30px;

        h4 {
            padding-top: 0px;
            align-self: center;
            margin-bottom: 0px;
        }

        .card-icon {
            width: 80px;
            height: auto;
            margin-right: 20px;
        }
    }
}

.page_5 .contact-cards {
    .card-left h3, .card-left h3:hover {
        color: #3b3b3b !important;
    }

    .card-left .btn-img-bg, .card-right .btn-img-bg {
        background-color: #3f4726;
    }
}

.page_9 {
    
    .container{
        &.beratung {
            margin: 100px auto;
        }
    
        .bob-style {
            color: #fff;
            background-color: #3f4726;
        }
        .bob-close {
            display: none;
        }
    }
}