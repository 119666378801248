.benefits {
    .circle {
        background: $brand-sec;
        border-radius: 50%;
        padding: 20px;
        width: 140px;
        margin: 0 auto;

        img {
            width: 79px;
            height: 99px;
        }
    }
}