.navbar {
    .container {
        padding-bottom: 5px;
    }

    .nav-link-span {
        padding-left: $navbar-nav-link-padding-x;
        padding-right: $navbar-nav-link-padding-x;
    }
}

.nav-button {
    .nav-link {
        background: #f0ea9d;
        border-radius: 25px;
        .nav-link-span {
            color: #3b3b3b;
        }
    }
}

#bofu-landing {
    .navbar .nav-item {
        img {
            width: 45px;
            height: 45px;
            margin: 0;
        }

        #img-bund {
            width: 60px;
        }
    }
}